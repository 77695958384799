@charset "UTF-8";

@font-face {
    font-family: "tns-icon";
    src:url("../fonts/tns-icon.eot");
    src:url("../fonts/tns-icon.eot?#iefix") format("embedded-opentype"),
    url("../fonts/tns-icon.woff") format("woff"),
    url("../fonts/tns-icon.ttf") format("truetype"),
    url("../fonts/tns-icon.svg#tns-icon") format("svg");
    font-weight: normal;
    font-style: normal;

}

[data-icon]:before {
    font-family: "tns-icon" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "tns-icon" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
    content: "\61";
}
.icon-phone:before {
    content: "\63";
}
.icon-bars:before {
    content: "\62";
}
