@use './theme.scss';
@use './animations.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: clcFontStyle;
  src: url(../fonts/ScriptMTBold.ttf);
  /*font-weight: normal;*/
}

.font-clc {
  font-family: clcFontStyle;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}