@import 'variables.scss';

:where(.css-dev-only-do-not-override-1605uag).ant-btn-primary {
    color: #fff;
    background-color: $primary-color;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

:where(.css-dev-only-do-not-override-17a39f8).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-17a39f8).ant-menu-light>.ant-menu .ant-menu-item-selected {
    background-color: $primary-color;
    color: #fff;
}
