.main-area-marquee-fade {
    animation: 4s fade-in;
  }
  
  .fade {
    animation: 1s fade-in;
  }
  
  /*Animations*/
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }